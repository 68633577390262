footer {
	
	
	.disktop-footer li {
    	display: inline;
	}

    .footer-navigation a:hover,  .footer-navigation a {
    	text-decoration: none;
	}

    .footer-navigation a:hover,  .footer-navigation a {
    	color: #2b323c;
	}

	.disktop-footer li {
    	padding-right: 1.5rem !important;
	}

    .mobile-footer {
     	padding-left: 0;	
    }
   
   .mobile-footer li {
    	padding-bottom: 0.5rem ;
	}

	ul:not(.disktop-footer) li:before {
	    content: "";
	    position: absolute;
	    left: 0;
	    padding: 0px;
	    text-indent: -5px;
	    margin-right: 20px;
	}

  	ul{
    	list-style-type: none;
    }
}



footer {

	margin-top:-5px;
}	