$grid-breakpoints: (
    xs: 0,
    sm: 768px,
    md: 1074px,
    lg: 1280px,
    xl: 1530px
);

$container-max-widths: (
        sm: 628px,
        md: 964px,
        lg: 1020px,
        xl: 1270px
);

$spacer: 1rem !default;

$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 2),
  6: ($spacer * 3),
  7: ($spacer * 5),
  8: ($spacer * 8)
);
 
$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:                $font-size-base * 1.25 !default;
$font-size-sm:                $font-size-base !default;

$h1-font-size:                $font-size-base * 2.5 !default;
$h2-font-size:                $font-size-base * 2 !default;
$h3-font-size:                $font-size-base * 1.55 !default;
$h4-font-size:                $font-size-base * 1.4 !default;
$h5-font-size:                $font-size-base * 1.25 !default;
$h6-font-size:                $font-size-base !default;

$font-family-sans-serif: "Neue Haas Unica W01 Regular", sans-serif;
/**$headings-font-family: "Neue Haas Unica W01 Light";*/

$font-weight-normal:          400 !default;
$font-weight-bold:            $font-weight-normal;
$font-weight-bolder:			 $font-weight-normal;


$enable-rounded: false;

$btn-border-width: 	0;
$btn-font-size: 		18px;
$btn-padding-y:	 	23px;
$btn-padding-x:		60px;

$baymevbm: $baymevbmBlue;
$vbw: $vbwBlue;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "baymevbm":   $baymevbm,
    "vbw":  		  $vbw
  ),
  $theme-colors,
);

/* colors */
$primary: map-get($colors, 'blue') !default;
$secondary: map-get($colors, 'blue') !default;
$success: map-get($colors, 'green') !default;
$info: map-get($colors, 'yellow') !default;
$warning: map-get($colors, 'blue') !default;
$danger: map-get($colors, 'red') !default;
$light: map-get($colors, 'light') !default;
$dark:  map-get($colors, 'black')  !default;
$white:  map-get($colors, 'white')  !default;

$link-color: #5687B9;
$link-hover-color: $link-color;
$link-hover-decoration: none;

$max-font-size: 50 !default;
$min-font-size: 10 !default;
$font-sizes: () !default;

@if length($font-sizes) == 0 {
  @for $size from $max-font-size through $min-font-size {
    $font-sizes: append($font-sizes, $size);
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $size in $font-sizes {
      .font-size#{$infix}-#{$size} { font-size: #{$size}px !important; }
    }
  }
}