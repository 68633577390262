.item {
 	background-position: center;
    min-height: 170px;
    background-size: cover;
    cursor: pointer;
 }
 
 .owl-prev{
    position: absolute;
    right: 100%;
}

.slider-module .owl-nav button {
    position: absolute;
    top: -5px;
    height: 100%;
    background-color: rgba(79, 149, 199,.9) !important; 
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 15px;
    cursor: pointer;
    margin: 0;
    width: 20px;
    border-radius: 0 !important;
    z-index: 2;
    border: none;
}


.slider-module .owl-nav button:before {
    background-repeat: no-repeat;
    background-size: cover;
    content: " ";
    width: 10px;
    height: 18px;
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}

.slider-module   .owl-nav button.owl-next:before {
    background-image: url(/img/icons/carousel/arrow_right.png);
}
.slider-module  .owl-nav button.owl-prev:before {
    background-image: url(/img/icons/carousel/arrow_left.png);
}

.owl-nav span {
 display: none;
}

.slider-module .owl-dots {
    position: absolute;
    top: 100%;
    transform: translateY(-100%);
    left: 0;
    right: 0;
    height: 6px;
    z-index: 2;
    text-align: start;
}

.slider-module .owl-dots .owl-dot.active span {
    background-color: #4D90CD !important;
}

.slider-module .owl-dots .owl-dot span {
    width: 100%;
    border-radius: 0;
    height: 6px;
    margin: 0;
    display: block;
}

.slider-module .owl-dots .owl-dot {
    flex-grow: 1;
    padding: 0;
    border: 0;
    margin-top: 25px;
}