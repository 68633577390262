
@include respond-above(xs) {
  body {
  	
  }
}

@include respond-below(sm) {
  body {
  	
  	
  }
}

@include respond-above(md) {
  body {
  	
  	
  	
  }
}

@include respond-below(md) {
  body {

   .img-up {
   	   width: 410px;
   	   height: intrinsic;
   	}
  
  }
}
 

@font-face{
    font-family:"Neue Haas Unica W01 Light";
    src:url("/fonts/001e59a9-197e-4fb1-9e72-cef21b186c03.eot?#iefix");
    src:url("/fonts/001e59a9-197e-4fb1-9e72-cef21b186c03.eot?#iefix") format("eot"),url("/fonts/e5716290-d41e-4c97-a27c-7a20a46ddf45.woff2") format("woff2"),url("/fonts/b681571f-aab2-4935-a99c-6ee1063ce638.woff") format("woff"),url("/fonts/1d88a9b0-aac0-40df-baf7-a630cd19cc5d.ttf") format("truetype");
}
@font-face{
    font-family:"Neue Haas Unica W01 Regular";
    src:url("/fonts/0ebd7648-5847-404a-a88a-28a353c0921c.eot?#iefix");
    src:url("/fonts/0ebd7648-5847-404a-a88a-28a353c0921c.eot?#iefix") format("eot"),url("/fonts/e0d80810-f7e3-4fea-8c57-ef8116f0465d.woff2") format("woff2"),url("/fonts/27645c8a-608b-4abf-a2f1-c4407b576723.woff") format("woff"),url("/fonts/bd3b3439-8eff-445c-80ed-87064138ca7d.ttf") format("truetype");
}
@font-face{
    font-family:"Neue Haas Unica W01 Medium It";
    src:url("/fonts/56b738a7-54b9-4e8c-a470-d96972ab3303.eot?#iefix");
    src:url("/fonts/56b738a7-54b9-4e8c-a470-d96972ab3303.eot?#iefix") format("eot"),url("/fonts/2418a824-3af2-42d7-a62e-f7a77cf487d1.woff2") format("woff2"),url("/fonts/86b76db5-9343-473a-a106-60683cec7626.woff") format("woff"),url("/fonts/2b883332-01dc-4b55-ac6a-d24c84f0e334.ttf") format("truetype");
}

.light, .thin {
	font-family: "Neue Haas Unica W01 Light";
}


h1, .h1, h2, .h2, h3, .h3 {
	font-family: "Neue Haas Unica W01 Light";
}


.light {
	font-family: "Neue Haas Unica W01 Light";
}


.font-light {
    font-family:'Neue Haas Unica W01 Light';
}

.regular,
.font-regular {
    font-family:'Neue Haas Unica W01 Regular';
}

.font-medium,
strong,
.strong, .bold {
    font-family:'Neue Haas Unica W01 Medium It';
}


/* colors */
/* COLORS */

@each $name, $color in $colors {
    
    .#{$name} { 
        color: $color;
    }    
    
    .bg-#{$name} { 
        background-color: $color;
    }
    
}

@each $name, $background in $backgrounds {
    
    .bg-#{$name} { 
        background-color: $background;
    }
    
}

.rot-180{
  transform: rotate(180deg);
}

.lounge-brochure{
	background-color: #a278bc;
}

.navi-bar a:hover, .simple-header inner-diagonal-box a:hover {
    color: #ffffff;
    text-decoration: none;
}



ul:not(.checklist-white):not(.checklist-blue):not(.no-list) {
    list-style: none;
    padding-left: 0;
}
ul:not(.checklist-white):not(.checklist-blue):not(.no-list) li {
    margin-bottom: 5px;
    position: relative;
    padding-left: 15px;
}

ul:not(.navigation):not(.checklist-blue):not(.no-list):not(.slideout-navi) li:before {
    content: "\2013";
    position: absolute;
    left: 0;
    padding: 0px;
    text-indent: -5px;
    margin-right: 20px;
}


@import "footer/footer.scss";




