
$darkBlue: #00A9EB !default;

$headerBlue: rgba(#00A9EB, 0.8);

$baymevbmBlue:				#00A9EB;
$vbwBlue:					#00A9EB;
$colorWhite:				#ffffff;

$belowHeaderBlue:			$vbwBlue;			

$logoWidth:					126px;			
$logoHeight:					126px;

$footerTopBlue:				rgba(#00A9EB, 0.15);
$footerTopBlueBaymeVbm:		rgba(#00A9EB, 1);
$footerTopBlueVbw:			rgba(#00A9EB, 1);

$colors: (
    'green': #66cc66, 
    'red': #ff6666, 
    'yellow': #ffd200, 
    'orange': rgb(245, 150, 0), 
    'light': rgba(0, 40, 80, 0.5), 
    'white': rgb(255, 255, 255), 
    'black': #2b323c,
    'blue': #4f95c9
);

$backgrounds: (
    'color-f1-95': rgba(0, 40, 80, 0.95), 
    'color-f1-80': rgba(0, 40, 80, 0.80), 
    'logo': rgb(0, 74, 151), 
    'dark-color': rgb(0, 27, 54), 
    'middle-color': rgba(160, 195, 235, 0.4), 
    'middle-color-full': rgb(160, 195, 235), 
    'normal-color': rgba(45, 105, 165, 0.8), 
    'light-grey': rgb(242, 244, 246), 
    'grey': rgba(1, 38, 80, 0.05)
);



/* ROTATION */
$rotations: (
    90,
    180,
    270
);

.icon-rotate {
    
    display: inline-block;
    
    @each $rotation in $rotations {
        &-#{$rotation}{
            @extend .icon-rotate;
            transform: rotate(#{$rotation}deg);
        }
    }
}