
header {
	
	.disktop-navi li{
    	display: inline;
    	@extend .pr-4;
	}

	.disktop-navi a:hover, .disktop-navi a {
	    @extend .black;
	    text-decoration: none;
	}

   .disktop-navi .active{
   	  @extend .strong;
   	  color: #00A9EB;
   	}

}



@include respond-above(xs) {
  header {
  	
  }
}

@include respond-below(sm) {

  	
  	
}

@include respond-above(md) {
  .member-header-img, .header-img {

        min-height: 225px;
    }
   
   .logo img {
   	  width:152px
   	}
   
  
.video-container {

    min-height: 300px;
    background-size: cover;
    background-position: center;
}
}

@include respond-below(md) {

 
 .logo img {
   	  width:80px
   	}
   


.video-container {

    min-height: 218px;
    background-size: cover;
    background-position: center;
}
}


.member-header-img {
        background-image: url(/img/background/Kongress.JPG);
        background-size: cover;
        position: relative;
        margin-right: 0px;
        background-position-y: 20%;
        background-position: center;

    }
 .header-img {
        background-image: url(/img/background/thumb_16__header.jpeg);
        background-size: cover;
        position: relative;
        margin-right: 0px;
        background-position-y: 20%;
        background-position: center;

    }

.diagonal-box {
    	transform: skewY(-1.5deg);
}	
.inner-diagonal-box {
   	    transform: skewY(1.5deg);
}

.vertical-middle-align {
    display: flex;
    align-items: center;
}

.simple-header .right-arrow {
	width: 45px;
}

.simple-header a:hover {
    color: white;
    text-decoration: none;
}

.right-arrow {
	width: 60px;
}

.icon-arrow {
	width: 22px;
}

.slideout-button {
    width: 24px;
    height: 24px;
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    position: relative;
}



.toolbar-item {
    height: 22px;
    line-height: 22px;
    background: no-repeat center center;
   
    
}

.toolbar {
    position: absolute;
    width: 300px;
    z-index: 100;
    right: 0;
    top: 0;
}

.slideout-button .slideout-button-close {
    opacity: 0;
}


.slideout-button span {
    display: block;
    height: 3px;
    background: #4f95c9;
    margin-bottom: 5px;
}

.slideout-menu {
    width: 350px;

}
.slideout-menu-open {
    right: -350px;
}
.slideout-menu-right {
    display: none;
    transition: all 0.2s;
}

.slideout-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    min-height: 100vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    z-index: 0;
    display: none;
    overflow: auto;
    background-color: #E6ECF1;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
   .slideout-menu {
    position: absolute;
    top: 0;
    bottom: 0;
    min-height: 100vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    z-index: 0;
    display: none;
    overflow: auto;
    background-color: #005AAA;
} 
}

.slideout-panel {
    position: relative;
    z-index: 1;
    will-change: transform;
    min-height: 100vh;
}
.slideout-menu-open {
    display: block;
}

.slideout-open .slideout-panel {
    -webkit-box-shadow: 3px 0px 6px -2px rgba(0,0,0,0.15);
    -moz-box-shadow: 3px 0px 6px -2px rgba(0,0,0,0.15);
    box-shadow: 3px 0px 6px -2px rgba(0,0,0,0.15);
}

.slideout-button-open span:first-child {
    -moz-transform: rotate(-45deg) translate(0,0);
    -ms-transform: rotate(-45deg) translate(0,0);
    -webkit-transform: rotate(-45deg) translate(0,0);
    transform: rotate(-45deg) translate(0,0);
    position: relative;
    top: 9px;
}

.slideout-button-open span {
    opacity: 1;
    -webkit-transition: 0.0;
    display: block;
    width: 25px !important;
    -moz-transform: rotate(45deg) translate(0,0);
    -ms-transform: rotate(45deg) translate(0,0);
    -webkit-transform: rotate(45deg) translate(0,0);
    transform: rotate(45deg) translate(0,0);
}


.slideout-button-open span:last-child {
    opacity: 0;
}

button.slideout-button.slideout-button-open {
    position: absolute;
}
.slideout-button-open span {
    background: #4f95c9;
    margin-bottom: 7px;
    
}

.slideout-button span:last-child {
    width: 24px;
}

.slideout-navi li a{
	@extend .black;
	
	}

.slideout-navi .active a{
	@extend .blue;
	
	}
.slideout-navi  li:before {
    content: "";
    position: absolute;
    left: 0;
    padding: 0px;
    text-indent: -5px;
    margin-right: 20px;
}
button:focus {
        	outline: none !important;
        }


.side-navigation li {
    padding-top: 10px;
    padding-bottom: 10px;
    display: block;
    float: none;
}

ul:not(.checklist-white):not(.checklist-blue):not(.no-list) {
    list-style: none;
    padding-left: 0;
}

ul:not(.checklist-white):not(.checklist-blue):not(.no-list) li {
    margin-bottom: 5px;
    position: relative;
    padding-left: 15px;
}

.slideout-navi .active {
	@extend .blue;
	}

.color{
	color: $baymevbmBlue;
}
