@mixin accordion() {
 
 
    display: flow-root;
    margin: 1rem 0;

    


    &-toggle {



      
        @extend .strong;

        position: relative;
        padding: 2rem 0;
        
    
        &:after {
            content: "\e917";
            font-family: 'icomoon';
            position: absolute;
            right: 2rem;
            top: 35%;
            @extend .icon-rotate-270;
            transition: 0.2s;
        }
        
        &-small {
            @extend .font-light;
            padding: 1rem 0 !important;
            width: 100% !important;
        } 
        
    }  
    
    &-toggle.collapsed {
        
        &:after {
            @extend .icon-rotate-90;
        }
        
    } 
}

.accordion {

    @include accordion(); 
    

    
    &-text {
             
        @include accordion(); 
                    
        @extend .border-top;
        
        &-toggle.collapsed {
            @extend .border-bottom;
        }  
    }    
}


.collapsable .icon-right_small {
    @extend .icon-rotate-270;
}

.collapsable.collapsed .icon-right_small {
    @extend .icon-rotate-90;
}
