/** assets/css/ */


@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/_mixins.scss';

@import "variables.scss";
@import "bootstrap_variables.scss";
@import "~bootstrap/scss/bootstrap";

@import "util.scss";
@import "../lib/lity/lity.min.css";
@import "../lib/magnific/magnific-popup.css";
@import "../lib/owl/owl.carousel.min.css";
@import "../lib/owl/owl.theme.default.min.css";


@import "core.scss";
@import "header/header.scss";
@import "modules/slider.scss";
@import "modules/accordion.scss";